import Utilities from "./utilities";

class AutoCompleteSearch {
    constructor(autoComplete) {
        this.autoComplete = autoComplete;
        this.options = JSON.parse(autoComplete.dataset.autoComplete);
        this.suggestionsTemplate = document.getElementById(this.options.suggestionsTemplate);
        this.suggestionsContainer = document.getElementById(this.options.suggestionsContainer);
        this.inputKeyDown = this.inputKeyDown.bind(this);
    }

    initialise() {
        this.autoComplete.addEventListener('keydown', Utilities.debounce(this.inputKeyDown));
    }

    inputKeyDown(event) {
        const input = event.target;
        const query = input.value;
        if (!query || query.length < 2) return;
    
        this.getSearchSuggestions(query, this.options);
    }

    getSearchSuggestions(query, options) {
        fetch(options.url, {
            method: 'POST',
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                query: query,
                siteId: options.siteId,
                cultureCode: options.cultureCode
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`The search suggestion API returned the response ${response}`)
            }

            return response.json();
        })
        .then(data => this.showSearchSuggestions(data))
        .catch(error => {
            this.hideSearchSuggestions();
            console.error(`There was a problem retrieving search results: ${error}`);
        });
    }

    showSearchSuggestions(suggestions) {
        Utilities.fillTemplateWithData(this.suggestionsTemplate, this.suggestionsContainer, suggestions);
    }

    hideSearchSuggestions() {
        this.suggestionsContainer.classList.remove('is-active');
    }
}

window.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('[data-auto-complete]').forEach(autoComplete => {
        new AutoCompleteSearch(autoComplete).initialise();
    });
});