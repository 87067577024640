const tail = require('tail.select.js/js/tail.select.min.js');

module.exports = function () {
	const resets = document.querySelectorAll('button[name=reset]');
	resets.forEach(reset => {
		reset.addEventListener('click', function () {
			const form = reset.closest('form:not(.form__tail)');
			if (form) {
				const fields = form.querySelectorAll('input:not([type=submit]):not([type=reset]), select');
				fields.forEach(field => {
					field.value = '';
				});
			}
		});
	});

	const productSearchForm = document.getElementById('product-search');
	if (productSearchForm) {
		const action = productSearchForm.dataset.ajaxAction;
		productSearchForm.addEventListener('submit', function (e) {
			e.preventDefault();
			formSubmit(productSearchForm, action, e.submitter.name === 'page');
		});

		const pageInput = document.createElement('input');
		pageInput.type = 'hidden';
		pageInput.name = 'page';
		productSearchForm.appendChild(pageInput);

		document.querySelectorAll('button[name=page][form=' + productSearchForm.id + ']').forEach(button => {
			button.addEventListener('click', function () {
				pageInput.value = button.value;
			});
		});

		productSearchForm.querySelectorAll('[type=submit]').forEach(submitButton => {
			pageInput.value = '';
		});
	}

	initialiseFilters();
}();

function formSubmit(form, action, isPager) {
	filter(form, action, isPager);
}

function initialiseFilters() {
	const filterElements = document.querySelectorAll('.filter__filter--tail');
	const filters = [];
	filterElements.forEach(filter => {
		filters.push(tail(filter, {
			search: false,
			deselect: true,
			multiContainer: filter.dataset.pillsContainerId && `#${filter.dataset.pillsContainerId}`
		}));
	});

	setupFilters(filters);
}

function setupFilters(filters) {
	const clearFilterButton = document.querySelector('.form__clear-filters');

	for (let i = 0; i < filters.length; i++) {
		const multiSelectWithFilters = filters[i];
		const originalSelect = multiSelectWithFilters.e;
		const options = multiSelectWithFilters.options;
		const parentForm = originalSelect.closest('form');

		//setup the select/deselect logic
		setupSelectDeselect(multiSelectWithFilters, originalSelect, options, parentForm);

		//setup the styling for the options
		setupStyling(multiSelectWithFilters, originalSelect);

		//setup clear filters button
		if (clearFilterButton)
			setupClearFiltersButton(originalSelect, options, clearFilterButton);

		//show/hide pills containers
		var pillsContainer = document.querySelector(`#${originalSelect.dataset.pillsContainerId}`);
		setupPillsContainer(pillsContainer, originalSelect, options);
	}
}

function setupPillsContainer(pillsContainer, originalSelect, options) {
	//shows/hide pills container based on selected filters
	toggleSelectedFiltersContainer(pillsContainer, options);

	var clearer = document.querySelector(`#${originalSelect.dataset.clearer}`);
	if (clearer) {
		clearer.addEventListener('click', function () {
			var selectOptions = originalSelect.querySelectorAll('option');
			selectOptions.forEach(option => {
				if (option.selected) {
					options.handle('unselect', option, '#');
				}
			});
		});
	}
}

function setupClearFiltersButton(originalSelect, options, clearFilterButton) {
	const childOptions = originalSelect.querySelectorAll('option');
	clearFilterButton.addEventListener('click', function () {
		childOptions.forEach(option => {
			if (option.selected) {
				options.handle('unselect', option, '#');
			}
		})
	});
}

function setupStyling(multiSelectWithFilters, originalSelect) {
	const childOptions = multiSelectWithFilters.dropdown.querySelectorAll('.dropdown-option');
	childOptions.forEach(option => {
		let correspondingOption = originalSelect.querySelector(`option[value='${option.dataset.key}']`);
		if (!correspondingOption) return;

		let level = correspondingOption.dataset.level;

		if (level > 1 || correspondingOption.dataset.hasChildren == 'true') {
			option.classList.add(`dropdown-option--level${level}`)
		}
	})
}

function setupSelectDeselect(multiSelectWithFilters, originalSelect, options, parentForm) {
	let automaticChange = false;

	multiSelectWithFilters.on('change', function (item, state) {
		if (automaticChange) return;
		automaticChange = true;

		// if you selected/unselected a parent, select/unselect all children
		const childOptions = originalSelect.querySelectorAll(`[data-parent-id="${item.key}"]`);
		childOptions.forEach(option => {
			options.handle(state, option, item.group);

			//select/unselected all granchildren
			const grandChildOptions = originalSelect.querySelectorAll(`[data-parent-id="${option.value}"]`);
			grandChildOptions.forEach(gOption => {
				options.handle(state, gOption, item.group);
			});
		});

		// if you unselected a child, unselect its parent and grandparent
		if (state === 'unselect') {
			const parentId = item.option.dataset.parentId;
			const parentOption = options.get(parentId, item.group);

			//if parent exists (top level ones don't have parents)
			if (parentOption && parentOption.selected) {
				options.unselect(parentOption, item.group);

				const grandparentId = parentOption.option.dataset.parentId;
				const grandparentOption = options.get(grandparentId, item.group);
				if (grandparentOption && grandparentOption.selected) {
					options.unselect(grandparentOption, item.group);
				}
			}
		}

		// if there are now any parents which have all children selected, select them
		if (state === 'select') {
			const parentId = item.option.dataset.parentId;

			const parentOption = options.get(parentId, item.group);

			let allChildrenSelected = true;
			const childOptions = originalSelect.querySelectorAll(`[data-parent-id="${parentId}"]`);
			childOptions.forEach(option => {
				allChildrenSelected = allChildrenSelected && options.get(option, item.group).selected;
			});

			if (allChildrenSelected) {
				options.select(parentOption, item.group);
			}
		}

		//show/hide pills container if empty/full
		var pillsContainer = document.querySelector(`#${originalSelect.dataset.pillsContainerId}`);
		toggleSelectedFiltersContainer(pillsContainer, options);

		//handle auto-submit
		if (parentForm && parentForm.classList.contains('form__tail--autosearch')) {
			formSubmit(parentForm, parentForm.dataset.ajaxAction, false);
		}

		automaticChange = false;
	});
}

function toggleSelectedFiltersContainer(pillsContainer, options) {
	if (pillsContainer) {
		var pillsParent = pillsContainer.closest('.selected-filters__pill-box')
		if (pillsParent) {
			pillsParent.classList.toggle('hide', options.selected.length === 0);
		}
	}
}

function setupPagerItem(pager, templateId, pageNumber) {
	if (!pageNumber) return;

	const template = document.getElementById(templateId);
	const pageElement = template.content.cloneNode(true);
	const pageInnerElement = pageElement.firstElementChild.firstElementChild;

	if (pageInnerElement.tagName === 'A') {
		const url = new URL(location.href);
		url.searchParams.set('page', pageNumber);
		pageInnerElement.href = url;
	} else if (pageInnerElement.tagName === 'BUTTON') {
		pageInnerElement.value = pageNumber;
		pageInnerElement.addEventListener('click', function () {
			document.querySelector('input[name=page]').value = pageNumber;
		});
	}

	pageInnerElement.setAttribute('aria-label', pageInnerElement.getAttribute('aria-label').replace('{pageNumber}', pageNumber));

	if (!pageInnerElement.innerHTML)
		pageInnerElement.innerText = pageNumber;

	pager.appendChild(pageElement);
}

function filter(filterForm, action, isPager) {
	const httpRequest = new XMLHttpRequest();
	const loadingSpinner = document.getElementById('loading-spinner');
	if (loadingSpinner && !loadingSpinner.classList.contains('loading'))
		loadingSpinner.classList.add('loading');
	location.href = '#product-search';
	httpRequest.onreadystatechange = function () {
		if (this.readyState === this.DONE) {
			const productListing = document.getElementById('product-listing');
			const cardsContainer = productListing.querySelector('.simple-cards__container');
			while (cardsContainer.childNodes.length) {
				cardsContainer.firstChild.remove();
			}

			const productSearchResponse = JSON.parse(this.responseText);
			if (productSearchResponse.products.length) {
				const simpleCardTemplate = document.getElementById('simple-card-template');
				productSearchResponse.products.forEach(cardData => {
					const productCard = simpleCardTemplate.content.cloneNode(true);

					const anchor = productCard.querySelector('a');
					anchor.href = cardData.url;

					const titleEl = productCard.querySelector('h3');
					titleEl.innerText = cardData.title;

					const subtitleEl = productCard.querySelector('.simple-card__subtitle');
					if (cardData.subtitle) {
						subtitleEl.innerText = cardData.subtitle;
					} else {
						titleEl.classList.add('mb-4');
						subtitleEl.remove();
					}

					const summaryEl = productCard.querySelector('p');
					if (cardData.summary) {
						summaryEl.innerText = cardData.summary;
					} else {
						summaryEl.remove();
					}

					const imageEl = productCard.querySelector('img');
					if (cardData.imageUrl) {
						imageEl.src = cardData.imageUrl;
						imageEl.alt = cardData.imageAltText;
					} else {
						imageEl.remove();
					}

					const badgesContainerEl = productCard.querySelector('.simple-card__badges');
					if (cardData.badges?.length) {
						const badgeTemplate = document.getElementById('badge-template');
						cardData.badges.forEach(badge => {
							const badgeEl = badgeTemplate.content.cloneNode(true);
							const badgeImageEl = badgeEl.querySelector('img');
							const badgeImageUrlFormat = decodeURI(badgeImageEl.src);
							badgeImageEl.src = badgeImageUrlFormat.replace('\{path\}', badge.path).replace('\{filename\}', badge.filename);
							badgeImageEl.alt = badge.title;
							badgesContainerEl.appendChild(badgeEl);
						});
					} else {
						badgesContainerEl.remove();
					}

					cardsContainer.appendChild(productCard);
				});

				const pagerWrapper = document.getElementById(filterForm.id + '-pager');
				const pager = pagerWrapper.querySelector('.pager');
				while (pager.childNodes.length) {
					pager.firstChild.remove();
				}

				if (productSearchResponse.pager.pages.length <= 1) {
					pagerWrapper.classList.add('d-none');
				} else {
					pagerWrapper.classList.remove('d-none');
					setupPagerItem(pager, 'pager-previous-set-template', productSearchResponse.pager.previousSetPage);

					for (let i = 0; i < productSearchResponse.pager.pages.length; i++) {
						const page = productSearchResponse.pager.pages[i];
						if (page.isSelected)
							setupPagerItem(pager, 'pager-selected-page-template', page.number);
						else
							setupPagerItem(pager, 'pager-page-template', page.number);
					}

					setupPagerItem(pager, 'pager-next-set-template', productSearchResponse.pager.nextSetPage);
				}
			} else {
				const noResultsTemplate = document.getElementById('no-results-template');
				const noResultsMessageEl = noResultsTemplate.content.cloneNode(true);
				cardsContainer.appendChild(noResultsMessageEl);
				const pagerWrapper = document.getElementById(filterForm.id + '-pager');
				pagerWrapper.classList.add('d-none');
			}

			if (loadingSpinner)
				loadingSpinner.classList.remove('loading');

			filterForm.scrollIntoView(true);
		}
	};

	const formData = {};
	for (let [key, value] of new FormData(filterForm)) {
		if (formData[key] !== undefined) {
			if (!Array.isArray(formData[key])) {
				formData[key] = [formData[key]];
			}
			formData[key].push(value);
		} else {
			formData[key] = value;
		}
	}

	if (!isPager) {
		delete formData['page'];
	}

	formData['nodeId'] = filterForm.dataset.nodeId;

	if (formData['filters'] && formData['filters'].length && Array.isArray(formData['filters']) != true) {
		var val = formData['filters'];
		formData['filters'] = [];
		formData['filters'].push(val);
	}

	httpRequest.open('POST', action, true);
	httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
	httpRequest.send(JSON.stringify(formData));

	const newUrl = new URL(window.location);
	if (formData['search']) {
		newUrl.searchParams.set('search', formData['search']);
	} else {
		newUrl.searchParams.delete('search');
	}

	if (formData['filters']) {
		if (Array.isArray(formData['filters'])) {
			newUrl.searchParams.set('filters', formData['filters'].filter(item => item).join(','));
		} else {
			newUrl.searchParams.set('filters', formData['filters']);
		}
	} else {
		newUrl.searchParams.delete('filters');
	}

	if (formData['page']) {
		newUrl.searchParams.set('page', formData['page']);
	} else {
		newUrl.searchParams.delete('page');
	}

	window.history.pushState(null, '', newUrl.toString());
}