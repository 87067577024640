class Video {
    constructor(videoEl) {
        this.videoEl = videoEl;
        this.videoPlayEl = videoEl.querySelector('[aria-label=play]');
        this.videoPlayerEl = videoEl.getElementsByTagName('video')[0];
        this.playVideo = this.playVideo.bind(this);
        this.videoPlayEl.addEventListener('click', this.playVideo);
    }

    playVideo() {
        this.videoPlayerEl.play();
        this.videoPlayerEl.classList.add('is-playing');
        this.videoPlayerEl.controls = 'controls';
        this.videoPlayEl.removeEventListener('click', this.playVideo);
    }
}

window.addEventListener('DOMContentLoaded', function () {
    [...document.getElementsByClassName('video')].forEach(video => {
        new Video(video);
    });
});