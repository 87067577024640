const Siema = require('siema');

class Carousel {
    constructor(carouselEl) {
        this.carouselEl = carouselEl;
        this.carousel = null;
        this.carouselItems = carouselEl.children;
        this.containerEl = carouselEl.closest('[data-carousel-container]');
        this.initialised = false;
        this.perPageConfig = carouselEl.dataset.carouselPerPage ? JSON.parse(carouselEl.dataset.carouselPerPage) : null;
        this.pagination = document.getElementById(carouselEl.dataset.carouselPagination);
        this.paginationClickHandler = this.paginationClickHandler.bind(this);
    }

    initialise() {
        if (this.carouselEl && !this.initialised) {
            if (this.carouselItems.length > 0) {
                this.setUpCarousel();
            }
        }

        return this.carousel;
    }

    setUpCarousel() {
        const currentCarousel = this;
        const carouselConfig = {
            selector: this.carouselEl,
            duration: 400,
            easing: 'ease-out',
            startIndex: 0,
            draggable: true,
            multipleDrag: true,
            threshold: 20,
            loop: true,
            onInit: function () {
                currentCarousel.siemaInitHandler(this);
            },
            onChange: function() {
                currentCarousel.updateActiveSlide();
            }
        };

        let wholeItemsPerPage = 1;
        if (this.perPageConfig) {
            carouselConfig.perPage = this.perPageConfig;

            for (const viewport in this.perPageConfig) {
                if (window.innerWidth >= viewport) {
                    wholeItemsPerPage = this.perPageConfig[viewport];
                }
            }
        }

        if (this.pagination) {
            this.pagination.addEventListener('click', this.paginationClickHandler);
        }

        if (this.carouselItems.length > wholeItemsPerPage) {
            if (!this.carousel) {
                this.carousel = new Siema(carouselConfig);
                this.carouselItems = this.carousel.innerElements;
                this.carouselEl.nav = new CarouselNav(this.carousel, this.containerEl).initialise();
            } else {
                this.fixSubPixelRounding();
            }

            this.carouselEl.classList.remove('d-flex');
            this.carouselEl.classList.remove('justify-content-end');
            this.carouselInitialised = true;
        } else {
            if (this.carousel) {
                if (this.carouselEl.nav) this.carouselEl.nav.destroy();
                this.carousel.destroy(true);
                this.carousel = null;
                this.carouselItems = this.carouselEl.children;
            }

            this.carouselEl.classList.add('d-flex');
            this.carouselEl.classList.add('justify-content-end');
            this.carouselInitialised = true;
        }
    }

    siemaInitHandler(carousel) {
        this.carousel = carousel;
        this.fixSubPixelRounding();
        this.updateActiveSlide(0);
    }
    
    fixSubPixelRounding() {
        const slidesContainer = this.carousel.selector.children[0];
        const slidesContainerWidth = slidesContainer.getBoundingClientRect().width;
        let extraSlidesWidth = 0;
        this.carousel.selector.childNodes[0].childNodes.forEach(slide => {
	        const slideWidth = slide.getBoundingClientRect().width;
		    const roundedSlideWidth = Math.round(slideWidth);
            slide.style.width = roundedSlideWidth + 'px';
            extraSlidesWidth += roundedSlideWidth - slideWidth;
        });

        if (extraSlidesWidth > 0) {
	        slidesContainer.style.width = (slidesContainerWidth + extraSlidesWidth) + 'px';
        }
    }

    paginationClickHandler(event) {
        const pageLink = event.target.matches('[data-carousel-page]') ? event.target : event.target.closest('[data-carousel-page]');
        if (!pageLink) return;

        this.carousel.goTo(pageLink.dataset.carouselPage);
    }

    updateActiveSlide(currentSlide) {
        if (!currentSlide) currentSlide = this.carousel.currentSlide;

        this.carousel.innerElements.forEach((item, i) => {
            item.classList.toggle('is-active', i == currentSlide);
        });

        if (this.pagination) {
            this.updatePagination(currentSlide);
        }
    }

    updatePagination(currentSlide) {
        if (!this.pagination) return;

        this.pagination.querySelectorAll('[data-carousel-page]').forEach((page, i) => {
            page.classList.toggle('is-active', i == currentSlide);
        });
    }
}

class CarouselNav {
    constructor(carousel, containerEl) {
        this.carousel = carousel;
        this.carouselEl = containerEl || carousel.selector.parentElement;
        this.navClick = this.navClick.bind(this);
    }

    initialise() {
        this.carouselEl.addEventListener('click', this.navClick);
        return this;
    }

    destroy() {
        this.carouselEl.removeEventListener('click', this.navClick);
    }

    navClick(event) {
        if (event.target.matches('.carousel-nav__arrow--prev') || event.target.closest('.carousel-nav__arrow--prev')) {
            this.goPrev();
        } else if (event.target.matches('.carousel-nav__arrow--next') || event.target.closest('.carousel-nav__arrow--next')) {
            this.goNext();
        }
    }

    goPrev() {
        this.carousel.prev();
    }

    goNext() {
        this.carousel.next();
    }
}

window.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-carousel]').forEach(carouselEl => {
        carouselEl.carousel = new Carousel(carouselEl).initialise();
    });
});