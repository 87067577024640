class ProductDetails {
    constructor(productDetails) {
        this.productDetails = productDetails;
        this.productDetailsClick = this.productDetailsClick.bind(this);
        this.toggleSection = this.toggleSection.bind(this);
        this.hashChange = this.hashChange.bind(this);
    }

    initialise() {
        this.productDetails.addEventListener('click', this.productDetailsClick);
        window.addEventListener('hashchange', this.hashChange);
    }

    productDetailsClick(event) {
        const button = event.target.matches('button') ? event.target : event.target.closest('button');
        if (!button) return;

        const content = document.getElementById(button.getAttribute('aria-controls'));
        if (!content) return;

        this.toggleSection(button, content);
    }

    hashChange() {
        const button = document.getElementById(location.hash.substring(1) + '-toggle');
        if (!button) return;

        const content = document.getElementById(button.getAttribute('aria-controls'));
        if (!content) return;

        this.openSection(button, content);
    }

    toggleSection(button, content) {
        if (button.getAttribute('aria-expanded')) {
            this.closeSection(button, content);
        } else {
            this.openSection(button, content);
        }
    }

    openSection(button, content) {
        button.setAttribute('aria-expanded', 'true');
        content.classList.add('is-active');
    }

    closeSection(button, content) {
        button.removeAttribute('aria-expanded');
        content.classList.remove('is-active');
    }
}

window.addEventListener('DOMContentLoaded', function() {
    const productDetails = document.getElementById('product-details');
    if (productDetails) {
        new ProductDetails(productDetails).initialise();
    }
});