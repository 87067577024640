class Tabs {
    static keys = {
        end: 35,
        home: 36,
        left: 37,
        right: 39,
        up: 38,
        down: 40
    };

    static directions = {
        vertical: 0,
        horizontal: 992
    };

    constructor(tabsContainer) {
        this.tabsContainer = tabsContainer;
        this.tabs = tabsContainer.querySelectorAll('[role="tab"]');
        this.panels = tabsContainer.querySelectorAll('[role="tabpanel"]');

        this.tabsClickHandler = this.tabsClickHandler.bind(this);
        this.tabsKeyDownHandler = this.tabsKeyDownHandler.bind(this);
    }

    initialise() {
        this.tabsContainer.addEventListener('click', this.tabsClickHandler);
        this.tabsContainer.addEventListener('keydown', this.tabsKeyDownHandler);
        this.activateTab(0, false);
    }

    tabsClickHandler(event) {
        const tab = this.getClickedTab(event.target);
        if (!tab) return;
        
        const clickedTabIndex = [...this.tabs].indexOf(tab);
        if (clickedTabIndex != this.currentTabIndex) {
            this.activateTab(clickedTabIndex);
        }
    }

    tabsKeyDownHandler(event) {
        const tab = this.getClickedTab(event.target);
        if (!tab) return;
        
        switch (event.keyCode) {
            case Tabs.keys.end:
                event.preventDefault();
                this.activateTab(this.tabs.length - 1);
                break;
            case Tabs.keys.home:
                event.preventDefault();
                this.activateTab(0);
                break;
            case Tabs.keys.left:
                if (this.getTabsDirection() === Tabs.horizontal) {
                    event.preventDefault();
                    this.activatePreviousTab();
                }
                break;
            case Tabs.keys.right:
                if (this.getTabsDirection() === Tabs.horizontal) {
                    event.preventDefault();
                    this.activateNextTab();
                }
                break;
            case Tabs.keys.up:
                if (this.getTabsDirection() === Tabs.vertical) {
                    event.preventDefault();
                    this.activatePreviousTab();
                }
                break;
            case Tabs.keys.down:
                if (this.getTabsDirection() === Tabs.vertical) {
                    event.preventDefault();
                    this.activateNextTab();
                }
                break;
        }
    }

    getClickedTab(clickedElement) {
        return clickedElement.matches('[role="tab"]') ? clickedElement : clickedElement.closest('[role="tab"]');
    }

    activateTab(tabIndex, focus) {
        this.deactivateAllTabs();

        const tab = this.tabs[tabIndex];
        tab.removeAttribute('tabindex');
        tab.setAttribute('aria-selected', 'true');
        var panelId = tab.getAttribute('aria-controls');

        //activate panel
        for (let i = 0; i < this.panels.length; i++) {
            if (this.panels[i].id == panelId) {
                this.panels[i].removeAttribute('hidden');
            }
        }


        if (typeof focus === 'undefined' || focus) {
            tab.focus();
        }

        this.currentTabIndex = tabIndex;
    }

    activatePreviousTab() {
        this.activateTab(this.currentTabIndex == 0 ? this.tabs.length - 1 : this.currentTabIndex - 1);
    }

    activateNextTab() {
        this.activateTab(this.currentTabIndex == this.tabs.length - 1 ? 0 : this.currentTabIndex + 1);
    }

    deactivateTab(tabIndex, focus) {
        const tab = this.tabs[tabIndex];
        tab.setAttribute('tabindex', '-1');
        tab.setAttribute('aria-selected', 'false');
        var panelId = tab.getAttribute('aria-controls');

        //deactive panels
        for (let i = 0; i < this.panels.length; i++) {
            if (this.panels[i].id == panelId) {
                this.panels[i].setAttribute('hidden', 'hidden');
            }
        }

        this.currentTabIndex = null;

        if (focus) {
            tab.focus();
        }
    }

    deactivateAllTabs() {
        for (let i = 0; i < this.tabs.length; i++) {
            this.deactivateTab(i, false);
        }
    }

    getTabsDirection() {
        let currentDirection = Tabs.directions[0];

        for (let direction in Tabs.directions) {
            if (window.innerWidth >= direction) {
                currentDirection = direction;
            }
        }

        return currentDirection;
    }
}

window.addEventListener('DOMContentLoaded', function() {
    [...document.getElementsByClassName('tabs')].forEach(tabs => {
        new Tabs(tabs).initialise();
    });
})