class Hero {
    static carouselRotationTimeMs = 5000;

    constructor(heroEl) {
        this.heroEl = heroEl;
        this.videoEl = heroEl.querySelector('video');
        const videoDisabledMediaQueryString = "(prefers-reduced-motion: reduce), (max-width: 991.98px)"; // if you change this, also change it in the CSS
        this.videoDisabledMediaQuery = window.matchMedia(videoDisabledMediaQueryString);
        const carouselDisabledMediaQueryString = "(prefers-reduced-motion: reduce)";
        this.carouselDisabledMediaQuery = window.matchMedia(carouselDisabledMediaQueryString);
        this.heroImages = heroEl.querySelectorAll('.hero__media--image');
        this.carouselTimer = null;
        this.mediaQueryChangeHandler = this.mediaQueryChangeHandler.bind(this);
        this.videoControlEl = heroEl.querySelector('.hero__media-control--video');
        this.videoControlClickHandler = this.videoControlClickHandler.bind(this);
        this.carouselControlEl = heroEl.querySelector('.hero__media-control--carousel');
        this.carouselControlClickHandler = this.carouselControlClickHandler.bind(this);
        this.touchScrolled = false;

        this.onTap(this.heroEl, (event) => {
            if (event.target.matches('a, button') || event.target.closest('a, button')) return;

            if (this.videoEl) this.toggleVideoState();
            this.heroEl.classList.add('is-active');
        });
    }

    initialise() {
        if (this.videoEl) {
            if (this.canPlayVideo()) {
                this.loadVideo();
            }
            
            this.videoDisabledMediaQuery.addEventListener('change', this.mediaQueryChangeHandler);
        }

        if (this.heroImages.length > 1) {
            this.setupCarousel();
            this.carouselDisabledMediaQuery.addEventListener('change', this.mediaQueryChangeHandler);
            if (this.canPlayCarousel()) this.startCarousel();
        }
    }

    canPlayVideo() {
        return this.videoEl && !this.videoDisabledMediaQuery.matches;
    }

    loadVideo() {
        if (!this.videoEl.dataset.src) return;

        this.videoEl.src = this.videoEl.dataset.src;
        this.videoEl.dataset.src = '';
        this.setupVideoControl();
    }
    
    canPlayCarousel() {
        return this.heroImages.length > 1 && !this.carouselDisabledMediaQuery.matches;
    }

    setupCarousel() {
        this.carouselControlEl.addEventListener('click', this.carouselControlClickHandler);
        this.carouselControlEl.setAttribute('aria-label', 'pause');
    }

    startCarousel() {
        if (this.carouselTimer) clearInterval(this.carouselTimer);

        this.carouselTimer = setInterval(() => {
            for (let i = 0; i < this.heroImages.length; i++) {
                const image = this.heroImages[i];
                if (image.classList.contains('is-active')) {
                    image.classList.remove('is-active');
                    const nextImage = this.heroImages[(i + 1) % this.heroImages.length];
                    nextImage.classList.add('is-active');
                    break;
                }
            }
        }, Hero.carouselRotationTimeMs);
    }

    pauseCarousel() {
        if (this.carouselTimer) clearInterval(this.carouselTimer);
    }

    mediaQueryChangeHandler() {
        if (this.canPlayVideo()) {
            this.loadVideo();
            this.videoDisabledMediaQuery.removeEventListener('change', this.mediaQueryChangeHandler);
        }

        if (this.heroImages.length > 1 && !this.canPlayCarousel()) this.pauseCarousel();
    }

    setupVideoControl() {
        this.videoControlEl.addEventListener('click', this.videoControlClickHandler);
        this.videoControlEl.setAttribute('aria-label', 'pause');
    }

    videoControlClickHandler() {
        this.toggleVideoState();
    }

    toggleVideoState() {
        if (!this.videoEl.paused) {
            this.videoEl.pause();
            this.videoControlEl.setAttribute('aria-label', 'play');
        } else {
            this.videoEl.play();
            this.videoControlEl.setAttribute('aria-label', 'pause');
        }
    }

    carouselControlClickHandler() {
        if (this.carouselControlEl.getAttribute('aria-label') === 'pause') {
            this.pauseCarousel();
            this.carouselControlEl.setAttribute('aria-label', 'play');
        } else {
            this.startCarousel();
            this.carouselControlEl.setAttribute('aria-label', 'pause');
        }
    }

    onTap(el, callback) {
        el.addEventListener('touchstart', () => {
            this.touchScrolled = false;
        }, false);

        el.addEventListener('touchmove', () => {
            this.touchScrolled = true;
        }, false);

        el.addEventListener('touchend', (event) => {
            if (this.touchScrolled) return;

            callback(event);
        }, false);
    }
}

window.addEventListener('DOMContentLoaded', function () {
    const hero = document.getElementById('hero');
    if (!hero) return;
    
    new Hero(hero).initialise();
});