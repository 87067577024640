﻿document.querySelectorAll('[data-location-finder]').forEach(locationFinder => {
    const options = JSON.parse(locationFinder.dataset.locationFinder);
    const field = document.getElementById(options.fieldId);
    if (field && navigator.geolocation) {
        locationFinder.addEventListener('click', function () {
            navigator.geolocation.getCurrentPosition((position) => {
                field.value = position.coords.latitude + ',' + position.coords.longitude;
            });
        });
    } else {
        locationFinder.addClass('d-none');
    }
});