class Accordion {
    constructor(accordion) {
        this.accordion = accordion;
        this.items = [...accordion.getElementsByClassName('accordion__item')];
        this.accordionClickHandler = this.accordionClickHandler.bind(this);
    }

    initialise() {
        this.accordion.addEventListener('click', this.accordionClickHandler);
        
        this.items.forEach(item => {
            const content = item.querySelector('.accordion__content');
            content.style.marginTop = "-" + content.offsetHeight + "px";
        });

        this.items.forEach(item => {
            const content = item.querySelector('.accordion__content');
            content.classList.add('is-initialised');
        });
    }

    destroy() {
        this.accordion.removeEventListener('click', this.accordionClickHandler);
        
        this.items.forEach(item => {
            const content = item.querySelector('.accordion__content');
            content.classList.remove('is-initialised');
        });

        this.items.forEach(item => {
            const content = item.querySelector('.accordion__content');
            content.style.marginTop = '';
        });
    }

    accordionClickHandler(event) {
        const toggle = event.target.matches('button') ? event.target : event.target.closest('button');
        if (!toggle) return;

        const content = document.getElementById(toggle.getAttribute('aria-controls'));
        if (!content) return;

        if (toggle.getAttribute('aria-expanded')) {
            toggle.removeAttribute('aria-expanded');
            content.classList.remove('is-active');
        } else {
            toggle.setAttribute('aria-expanded', true);
            content.classList.add('is-active');
        }
    }
}

function initialiseAccordions(reinitialise) {
    [...document.getElementsByClassName('accordion')].forEach(accordionEl => {
        if (reinitialise && accordionEl.accordion) {
            accordionEl.accordion.destroy();
            accordionEl.accordion = null;
        }

        if (!accordionEl.accordion) {
            accordionEl.accordion = new Accordion(accordionEl);
            accordionEl.accordion.initialise();
        }
    });
}

window.addEventListener('DOMContentLoaded', () => initialiseAccordions(false));
document.addEventListener('brand-styles-loaded', () => initialiseAccordions(true));

// the height might change when the font has loaded, so re-calculate heights
if (document.fonts && document.fonts.ready) {
    document.fonts.ready.then(() => initialiseAccordions(true));
}