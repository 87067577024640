﻿class Hotspot {
    constructor(hotspotImageEl) {
        this.hotspotImageEl = hotspotImageEl;
        this.hotspotMouseoverHandler = this.hotspotMouseoverHandler.bind(this);
        this.hotspotMouseoutHandler = this.hotspotMouseoutHandler.bind(this);
    }

    initialise() {
        this.hotspotImageEl.addEventListener('mouseover', this.hotspotMouseoverHandler);
        this.hotspotImageEl.addEventListener('mouseout', this.hotspotMouseoutHandler);
    }

    hotspotMouseoverHandler(event) {
        const hotspot = event.target.matches('.hotspot') ? event.target : event.target.closest('.hotspot');
        if (!hotspot) return;

        event.preventDefault();
        this.toggleHotspot(hotspot, true);
    }

    hotspotMouseoutHandler(event) {
        const hotspot = event.target.matches('.hotspot') ? event.target : event.target.closest('.hotspot');
        if (!hotspot) return;

        event.preventDefault();
        this.toggleHotspot(hotspot, false);
    }

    toggleHotspot(hotspot, show) {
        hotspot.classList.toggle('is-active', show);
    }
}

window.addEventListener('DOMContentLoaded', function () {
    [...document.getElementsByClassName('hotspots__wrapper')].forEach(hotspotImageEl => {
        new Hotspot(hotspotImageEl).initialise();
    });
});