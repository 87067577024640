// todo: keyboard navigation
class MainNav {
    static mobileNavBreakpoint = 992;

    constructor(mainNav) {
        this.body = document.getElementsByTagName('body')[0];

        this.mainNav = mainNav;
        this.mainNavContainer = document.getElementsByClassName('main-nav__container')[0];
        this.mainNavMenu = document.getElementById('main-nav-menu');
        this.mainNavClickHandler = this.mainNavClickHandler.bind(this);
        this.mainNavMouseoverHandler = this.mainNavMouseoverHandler.bind(this);
        this.mainNavMouseoutHandler = this.mainNavMouseoutHandler.bind(this);

        this.navToggle = document.getElementById('main-nav-toggle');

        this.topNav = document.getElementById('top-nav');

        this.searchToggle = document.getElementById('site-search-toggle');
        this.searchToggleClickHandler = this.searchToggleClickHandler.bind(this);
        this.searchInput = document.getElementById('site-search-input');

        this.languageSelectorMenu = document.getElementById('language-selector-menu');

        this.windowResizeHandler = this.windowResizeHandler.bind(this);
    }

    initialise() {
        this.mainNav.addEventListener('click', this.mainNavClickHandler);
        this.mainNav.addEventListener('mouseover', this.mainNavMouseoverHandler);
        this.mainNav.addEventListener('mouseout', this.mainNavMouseoutHandler);
        this.searchToggle.addEventListener('click', this.searchToggleClickHandler);
        window.addEventListener('resize', this.windowResizeHandler);

        if (window.innerWidth < MainNav.mobileNavBreakpoint) {
            this.mainNavMenu.classList.remove('is-active');
            this.topNav.classList.remove('is-active');
        }
    }

    mainNavClickHandler(event) {
        const toggleButton = this.getClosestElement(event, '[aria-controls]');
        if (!toggleButton) return;

        if (toggleButton.nodeName === 'A' && window.innerWidth >= MainNav.mobileNavBreakpoint) return;

        event.preventDefault();

        const menu = document.getElementById(toggleButton.getAttribute('aria-controls'));
        if (!menu) return;

        const otherToggles = document.querySelectorAll('[aria-controls="' + menu.id + '"]');

        this.toggleMenu(menu, otherToggles, !menu.classList.contains('is-active'));
    }

    mainNavMouseoverHandler(event) {
        if (window.innerWidth < MainNav.mobileNavBreakpoint) return;

        let toggleButton = this.getClosestElement(event, '[aria-controls]');
        if (!toggleButton) {
            const link = this.getClosestElement(event, '.main-nav__l1-link');
            if (link) toggleButton = link.closest('.main-nav__l1-item').querySelector('[aria-controls]');
        }

        if (!toggleButton) return;

        const menu = document.getElementById(toggleButton.getAttribute('aria-controls'));
        if (!menu) return;

        const allToggles = document.querySelectorAll('[aria-controls="' + menu.id + '"]');

        this.toggleMenu(menu, allToggles, true);
    }

    mainNavMouseoutHandler(event) {
        if (window.innerWidth < MainNav.mobileNavBreakpoint) return;

        let toggleButton = this.getClosestElement(event, '[aria-controls]');
        if (!toggleButton) {
            const link = this.getClosestElement(event, '.main-nav__l1-link');
            if (link) toggleButton = link.closest('.main-nav__l1-item').querySelector('[aria-controls]');
        }

        const menu = toggleButton ? document.getElementById(toggleButton.getAttribute('aria-controls')) : this.getClosestElement(event, '.main-nav__l2, .brand-nav, .language-selector__menu');
        if (!menu || menu.matches('.main-nav__l3')) return;

        const allToggles = document.querySelectorAll('[aria-controls="' + menu.id + '"]');

        const hoveredElements = Array.from(document.querySelectorAll(':hover'));
        if (!hoveredElements.some(el => el.id === menu.id) && !(toggleButton && hoveredElements.some(el => el.id === toggleButton.getAttribute('aria-controls')))) {
            this.toggleMenu(menu, allToggles, false);
        }
    }

    toggleMenu(menu, allToggles, toggleOn) {
        if (menu.matches('.main-nav__menu')) {
            if (toggleOn) {
                this.closeActiveDropDowns();
                this.openNav();
            } else {
                this.closeNav();
            }
        } else if (menu.matches('.main-nav__section')) {
            if (toggleOn) {
                if (menu.id === 'language-selector-menu') {
                    this.closeActiveDropDowns();
                    this.closeNav();
                    this.setBodyOverlay(true);
                    this.mainNavContainer.classList.add('is-active');
                }

                menu.classList.add('is-active');
                allToggles.forEach(control => {
                    control.setAttribute('aria-expanded', 'true');
                });

                if (menu.matches('.main-nav__l2')) {
                    const personaNav = menu.getElementsByClassName('main-nav__persona');
                    if (personaNav.length >= 1) {
                        personaNav[0].classList.add('is-active');
                    }
                    
                    if (window.innerWidth >= MainNav.mobileNavBreakpoint) {
                        [...menu.getElementsByClassName('main-nav__l3')].forEach(l3Menu => {
                            l3Menu.classList.add('is-active');
                            menu.querySelectorAll('[aria-controls="' + l3Menu.id + '"]').forEach(l3Toggle => {
                                l3Toggle.setAttribute('aria-expanded', true);
                            });
                        });

                        allToggles.forEach(toggle => {
                            if (toggle.nodeName === 'A') toggle.removeAttribute('role');
                        });
                    }

                    this.toggleTopNav(false);
                }

                if (window.innerWidth >= MainNav.mobileNavBreakpoint && (menu.matches('#brand-nav') || menu.matches('.main-nav__l2'))) {
                    this.setBodyOverlay(true);
                }

                if (menu.matches('.main-nav__l3')) {
                    const personaNav = menu.closest('.main-nav__l2').getElementsByClassName('main-nav__persona');
                    if (personaNav.length >= 1) {
                        personaNav[0].classList.remove('is-active');
                    }
                }
            } else {
                if (menu.id === 'language-selector-menu') {
                    this.setBodyOverlay(false);
                    this.mainNavContainer.classList.remove('is-active');
                }

                menu.classList.remove('is-active');
                allToggles.forEach(control => {
                    control.removeAttribute('aria-expanded');
                });

                if (menu.matches('.main-nav__l2')) {
                    const personaNav = menu.getElementsByClassName('main-nav__persona');
                    if (personaNav.length >= 1) {
                        personaNav[0].classList.remove('is-active');
                    }

                    if (window.innerWidth >= MainNav.mobileNavBreakpoint) {
                        [...menu.getElementsByClassName('main-nav__l3')].forEach(l3Menu => {
                            l3Menu.classList.remove('is-active');
                            menu.querySelectorAll('[aria-controls="' + l3Menu.id + '"]').forEach(l3Toggle => {
                                l3Toggle.removeAttribute('aria-expanded');
                            });
                        });

                        allToggles.forEach(toggle => {
                            if (toggle.nodeName === 'A') toggle.setAttribute('role', 'button');
                        });
                    }

                    this.toggleTopNav(true);
                }

                if (window.innerWidth >= MainNav.mobileNavBreakpoint && (menu.matches('#brand-nav') || menu.matches('.main-nav__l2'))) {
                    this.setBodyOverlay(false);
                }

                if (menu.matches('.main-nav__l3')) {
                    const personaNav = menu.closest('.main-nav__l2').getElementsByClassName('main-nav__persona');
                    if (personaNav.length >= 1) {
                        personaNav[0].classList.add('is-active');
                    }
                }
            }
        }
    }

    toggleTopNav(toggleOn) {
        this.topNav.classList.toggle('is-active', toggleOn || window.innerWidth >= MainNav.mobileNavBreakpoint);
    }

    searchToggleClickHandler() {
        if (this.searchToggle.getAttribute('aria-expanded')) {
            this.searchToggle.removeAttribute('aria-expanded');
        } else {
            this.closeActiveDropDowns();
            this.searchToggle.setAttribute('aria-expanded', 'true');
            this.searchInput.focus();
        }
    }

    windowResizeHandler() {
        this.setBodyOverlay();

        if (window.innerWidth >= MainNav.mobileNavBreakpoint) {
            this.mainNavMenu.classList.add('is-active');
            this.topNav.classList.add('is-active');

            [...document.getElementsByClassName('main-nav__persona')].forEach(personaNav => {
                personaNav.classList.add('is-active');
            });
        } else {
            this.mainNavMenu.classList.toggle('is-active', this.navToggle.getAttribute('aria-expanded'));
            this.topNav.classList.toggle('is-active', this.navToggle.getAttribute('aria-expanded') && !this.mainNavMenu.querySelectorAll('button[aria-expanded=true]').length > 0);
        }
    }

    setBodyOverlay(enable) {
        if (typeof enable === 'boolean') {
            this.body.classList.toggle('has-overlay', enable);
        } else if (window.innerWidth >= MainNav.mobileNavBreakpoint) {
            this.body.classList.remove('has-overlay');
        }
    }

    openNav() {
        this.navToggle.setAttribute('aria-expanded', 'true');
        this.setBodyOverlay(true);
        this.mainNavContainer.classList.add('is-active');
        this.mainNavMenu.classList.add('is-active');
        this.toggleTopNav(true);
    }

    closeNav() {
        this.navToggle.removeAttribute('aria-expanded');
        this.setBodyOverlay(false);
        this.mainNavContainer.classList.remove('is-active');
        this.mainNavMenu.classList.remove('is-active');
        this.toggleTopNav(false);
    }

    closeActiveDropDowns() {
        this.mainNav.querySelectorAll('button[aria-expanded=true]:not(#main-nav-toggle)').forEach(el => {
            el.removeAttribute('aria-expanded');
            document.getElementById(el.getAttribute('aria-controls')).classList.remove('is-active');
        });

        this.toggleTopNav(false);
    }

    getClosestElement(event, selector) {
        return event.target.matches(selector) ? event.target : event.target.closest(selector);
    }
}

window.addEventListener('DOMContentLoaded', function () {
    const mainNav = document.getElementById('main-nav');
    if (!mainNav) return;

    new MainNav(mainNav).initialise();
});