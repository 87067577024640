document.documentElement.classList.remove('no-js');
document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + 'px');

// use native image lazy-loading if it's available, otherwise load in lazysizes script
if ('loading' in HTMLImageElement.prototype) {
    const images = document.querySelectorAll('img[loading="lazy"]');
    images.forEach(img => {
        if (img.dataset.src) { img.src = img.dataset.src; }
        if (img.dataset.srcset) { img.srcset = img.dataset.srcset; }
        if (img.dataset.sizes) { img.sizes = img.dataset.sizes; }
        if (img.parentElement.tagName.toLowerCase() === 'picture') {
            img.parentElement.querySelectorAll('source').forEach(src => {
                if (src.dataset.srcset) { src.srcset = src.dataset.srcset; }
            });
        }
        img.classList.remove('lazyload');
    });
} else {
    const script = document.createElement('script');
    script.src = '/scripts/lazysizes.js';
    document.body.appendChild(script);
}

// global scripts
require('autocomplete');
require('geolocate');
require('form');
require('location-finder')

// component-specific scripts
require('main-nav');
require('hero');
require('video');
require('carousel');
require('hotspot');
require('accordion');
require('tabs');
require('modal');
require('_product-details');
require('_map');