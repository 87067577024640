import { Loader } from "@googlemaps/js-api-loader"

class Map {
    constructor(map) {
        this.map = map;
        this.geolocationHandler = this.geolocationHandler.bind(this);
    }

    getConfig() {
        return JSON.parse(this.map.dataset.map);
    }

    setConfig(config) {
        this.map.dataset.map = JSON.stringify(config);
    }

    initialise() {
        document.documentElement.addEventListener('geolocated', this.geolocationHandler);
        
        this.googleMap = new google.maps.Map(this.map,
            {
                zoom: 5,
                center: { lat: 0, lng: 0 },
                mapId: 'DEMO_MAP_ID'
            });

        this.setupMarkers();

        this.alignMap();
    }

    setupMarkers() {
        const config = this.getConfig();
        const locations = document.querySelectorAll('[data-map-reference=' + config.id + ']');

        config.markers.forEach((location, i) => {

            const map = this.googleMap

            function ColorLuminance(hex, lum) {

                // validate hex string
                hex = String(hex).replace(/[^0-9a-f]/gi, '');
                if (hex.length < 6) {
                    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                }
                lum = lum || 0;

                // convert to decimal and change luminosity
                var rgb = "#", c, i;
                for (i = 0; i < 3; i++) {
                    c = parseInt(hex.substr(i * 2, 2), 16);
                    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                    rgb += ("00" + c).substr(c.length);
                }

                return rgb;
            }

            let pin = new google.maps.marker.PinElement({
                background: location.pinBackgroundColour
            });


            if (location.pinGlyphColour) {
                pin.glyphColor = location.pinGlyphColour;
            } else {
                pin.glyphColor = ColorLuminance(pin.background, -0.5);
            }

            if (location.pinBorderColour) {
                pin.borderColor = location.pinBorderColour;
            } else {
                pin.borderColor = ColorLuminance(pin.background, -0.5);
            }

            const marker = new google.maps.marker.AdvancedMarkerElement({
                map,
                position: location.coords,
                content: pin.element
            });

            if (location.info) {
                const infoWindow = new google.maps.InfoWindow();

                var infoContent = `<div class='info-content'>
                                        <h4>${location.info}</h4>
                                        <p>${location.description}</p>
                                        <p>${location.emailAddr}</p>
                                        <p>${location.tel}</p>
                                        <a href="${location.website$}">
                                            ${location.website}
                                        </a>
                                   </div>`
                ;

                infoWindow.setContent(infoContent);

                marker.addListener('click', () => {
                    infoWindow.open({
                        anchor: marker,
                        map: this.googleMap,
                        shouldFocus: true
                    });
                });
            }

            if (locations.length > i) {
                locations[i].addEventListener('click', () => {
                    this.googleMap.setCenter(new google.maps.LatLng(location.coords.lat, location.coords.lng));
                    this.googleMap.setZoom(12);
                    google.maps.event.trigger(marker, 'click');
                });
            }
        });
    }

    alignMap() {
        let markers = this.getConfig().markers;
        if (markers.length == 1) {
            this.googleMap.setOptions({ maxZoom: 20 });
            google.maps.event.addListenerOnce(this.googleMap, 'bounds_changed', () => {
                this.googleMap.setZoom(8);
            });
        }

        const bounds = new google.maps.LatLngBounds();
        markers.forEach(location => {
            if (location.inFrame) bounds.extend(location.coords);
        });
        this.googleMap.fitBounds(bounds);
    }

    geolocationHandler(e) {
        const coords = e.detail.coords;

        const place = {
            lat: parseFloat(coords.latitude),
            lng: parseFloat(coords.longitude)
        };

        //todo - what does this actually do? just select the right country?
    }
}

window.addEventListener('DOMContentLoaded', function () {
    const loader = new Loader({
        apiKey: window.googleMapsApiKey,
        version: "weekly",
        libraries: ["marker"]
    });

    loader.load().then(() => {
        document.querySelectorAll('[data-map]').forEach(map => {
            new Map(map).initialise();
        });
    });
});