class Utilities {
    static debounce(func, timeout = 500) {
        let timer;

        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    static fillTemplateWithData(templateEl, containerEl, data) {
        const templateHtml = templateEl.innerHTML;
        let listHtml = '';

        data.forEach(item => {
            let itemHtml = templateHtml;
            
            Object.keys(item).forEach(property => {
                itemHtml = itemHtml.replace(`{{${property}}}`, item[property]);
            });

            listHtml += itemHtml;
        });

        containerEl.innerHTML = listHtml;
        containerEl.classList.toggle('is-active', data.length > 0);
    }
}

export default Utilities;