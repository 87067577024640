class Geolocate {
    locate() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.triggerGeolocationEvent(position);
                },
                () => {
                    this.throwError('There was an error getting your location, please try again later');
                }
            );
        } else {
            // Browser doesn't support Geolocation
            this.throwError('Your browser doesn\'t support geolocation, please enter your location above.');
        }
    }

    triggerGeolocationEvent(position) {
        const event = new CustomEvent('geolocated', { detail: position });
        document.documentElement.dispatchEvent(event);
    }

    throwError(error) {
        console.error(error); //todo
    }
}

window.geolocate = function() {
    new Geolocate().locate();
}